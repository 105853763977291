<template>
  <div class="searchwrap">
    <div>
       <ui-select :items="itemList" @input="getSelectValue" :type="'board'" :value="filterValue" :className="'searchselect'"/>
       <div class="wrapsearch">
          <a @click="onSearch" class="searchicon"><img src="@/assets_mobile/img/icon_searchs.svg" /></a>
          <input :class="'searchinput'" type="text" :placeholder="$t('front.search.emptySearch')" v-model="filterText"/>
       </div>
    </div>

  </div>
</template>

<script>
import UiSelect from '@/components/ui/UiSelect'
import BoardFilter from '@/components/common/BoardFilter'

export default {
  name: 'BoardFilterMobile',
  components: {
    UiSelect
  },
  mixins: [BoardFilter]
}
</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
